.card {
  background-color: var(--color-primary);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
  }
}

.image {
  display: block;
  width: 100%;
  max-width: 400px;
  mask-image: linear-gradient(to bottom, #000000 85%, transparent);
}

.link {
  text-decoration: none;
}

.title {
  color: var(--color-text-on-primary);
  text-align: center;
  font-size: 1.2rem;
  line-height: 1;
  padding-block: 6px 10px;
  padding-inline: 8px;
}
