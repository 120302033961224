.main {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  padding: 2rem 1rem;

  background-color: #ffffff;
  background-image: url("./assets/footer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right 0% bottom 0%;
}

.logo {
  text-align: center;
}

.logo h1 {
  font-size: 2.4rem;
  margin: 2px;
  color: var(--color-primary);
}

.logoImage {
  display: block;
  margin-inline: auto;
  width: 120px;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.15));
}

.links {
  max-width: 90%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.attribution {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 4px;
  color: #3f3f3f;
  font-size: 0.8rem;
}
